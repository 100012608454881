import React from 'react'

export const StepFour = () => {
  return (
    <div className='step-list'>
    <div className='step-item'>
        <div className="step-title">
            <h2>Question</h2>
        </div>
        
    </div> 
    </div>
    
  )
}
